import React from 'react';

const VideoCategories = () => {
  return (
    <div>
      Video Categories
    </div>
  )
}

export default VideoCategories;